@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  color: #32373C;
  scroll-behavior: smooth;
}

body {
  background-color: #F9FEFE;
  overflow-x: hidden;
}

button:disabled {
  background-color: silver;
  color: gray;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.body1 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.body2 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.caption1 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.caption2 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
}

h1 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.15px;
}

h2 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.15px;
}

h3 {
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.25px;
}

h4 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
}

h5 {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.25px;
}

h6 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input,
select,
textarea {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.spinner {
  display: inline-block;
  position: relative;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  animation: spin 1.4s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0dcaf0 transparent transparent transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -0.25s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.2s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.1s;
}

.typewriter-long {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(80, end);
}

.typewriter-long-delete {
  overflow: hidden;
  white-space: nowrap;
  animation: typing-delete 2s steps(80, end);
}

.typewriter-short {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 1s steps(40, end);
}

.typewriter-short-delete {
  overflow: hidden;
  white-space: nowrap;
  animation: typing-delete 2s steps(40, end);
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes typing-delete {
  from {
    width: 100%
  }

  to {
    width: 0
  }
}

@media only screen and (min-width: 481px) and (max-width: 720px) {
  .body1 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  .body2 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .caption1 {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
  }

  .caption2 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.15px;
  }

  h2 {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.25px;
  }

  h4 {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.25px;
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }

  h6 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
}

@media only screen and (max-width: 480px) {
  .body1 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .body2 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  .caption1 {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.4px;
  }

  .caption2 {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
  }

  h1 {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.25px;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.25px;
  }

  h4 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }

  h5 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  h6 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}